"use client"

import clsx from 'clsx';
import ColumnItem from '../ColumnItem';
import { IconFacebook, IconInstagram, IconLinkedIn, IconPinterest } from 'components/icons';
import Style from 'components/applications/header_footer/Footer/styles/Footer.module.scss';
import CallTracking from 'thirdparty/CallTracking';


function ContactNav(){

    return(
        <ul className={clsx(Style.list, Style.listContact, "vcard")} role="list">
            <li className={clsx(Style.item, Style.itemHeading)}>
                <h6 className={Style.listHeading}>
                    Contact Us
                </h6>
            </li>

            <ColumnItem
                href="/c/contact-us"
                title="Get in touch"
                text="Send Us A Message"
            />

            <li className={Style.item}>
                <span className={clsx(Style.listText)}>
                    <span className='org'>AuthenTEAK</span> Showroom<br/> 
                    <span className='adr'>
                        <span className='street-address'>1094 Huff Road</span><br/>
                        <span className='locality'>Atlanta</span>,&nbsp; 
                        <span className='region'>Georgia</span>&nbsp;
                        <span className='postal-code'>30318</span>
                    </span>
                </span>
            </li>

            {/* <li className={Style.item}>
                <CallTracking className={Style.listLink}>
                    <a href={`tel:${process.env.phone}`} title="Call us">
                        {process.env.phone}
                    </a>
                </CallTracking>
            </li> */}

            <li className={clsx(Style.item, Style.itemSocial)}>
                <h5 className={Style.listHeading}>
                    Follow Us
                </h5>
            </li>
            <li className={clsx(Style.item, Style.itemSocialLinks)}>
                <a href="https://www.linkedin.com/company/authenteak-outdoor-furniture/" target="_blank" rel="noreferrer" className={Style.socialLink} title="LinkedIn">
                    <IconLinkedIn width="20" height="20" />
                </a>
                <a href="https://www.instagram.com/authenteakoutdoorliving/" target="_blank" rel="noreferrer" className={Style.socialLink} title="Instagram">
                    <IconInstagram width="20" height="20" />
                </a>
                <a href="https://www.pinterest.com/authenteak/" target="_blank" rel="noreferrer" className={Style.socialLink} title="Pinterest">
                    <IconPinterest width="20" height="20" />
                </a>
                <a href="https://www.facebook.com/AuthenTEAK/" target="_blank" rel="noreferrer" className={Style.socialLink} title="Facebook">
                    <IconFacebook width="20" height="20"  />
                </a>
            </li>
        </ul>
    )
}

export default ContactNav;