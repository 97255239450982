"use client"

/* eslint-disable react/no-unknown-property */
/* eslint-disable react-hooks/exhaustive-deps */
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useState, useEffect, useContext, useRef } from 'react';
import { IconSearch } from 'components/icons';
import { GlobalContext } from 'contexts/GlobalContext';

// import Style from './HeaderSearch.module.scss';
import { usePathname, useSearchParams, useRouter } from 'next/navigation';
import makeQueryString from 'lib/Utils/makeQueryString';
import Input from 'components/forms/Input';

const Typeahead = dynamic(() => import("./Typeahead"))


function HeaderSearch({ headerIsFixed, order, isMobile, Style }) {
    const router = useRouter(); 
    const pathName = usePathname()
    const searchParams = useSearchParams()
    const searchTerm = searchParams.get("q")
    const searchControl = useRef()

    const [ query, setQuery ] = useState("")
    const [ placeholderText, setPlaceholder ] = useState("")
    const [ isInFocus, setInFocus ] = useState(false)

    const [ global, _ ] = useContext(GlobalContext)
    const isTrade = global.user.customer_group_id === 1;


    useEffect(() => {
        setQuery("")

    }, [pathName])



    useEffect(() => {
        if( searchTerm ){
            setQuery(searchTerm)
            searchControl.current.blur()
        }

    }, [ searchTerm ])



    function submitSearch(e){
        e.preventDefault()

        if ( e.type === 'submit' ) {  
            const pageQuery = makeQueryString({ 
                q: e.currentTarget[0]?.value
            })

            router.push('/search' + pageQuery)
        }
    }


    const search = (value, ref, e) => {
        // debugger
        if ( value !== "" ){
            setQuery(value)
            
        }else{
            setQuery("")
        }
    }


    useEffect(() => {
        setPlaceholder(isMobile && !headerIsFixed ? "Search" : "What are you looking for today?")

        function handleClickOutside(event) {
            if (searchControl.current && !searchControl.current.contains(event.target)) {
                setInFocus(false);
            }
        }
        
        document.addEventListener("mousedown", handleClickOutside);
        
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [headerIsFixed, isMobile ])






    return (
        <div className={clsx(Style.block, Style.mobile)} style={{ order: order }} itemScope itemType="https://schema.org/WebSite">
            <meta itemProp="url" content={process.env.NEXT_PUBLIC_siteUrl} />

            <form
                className={clsx(Style.group, !headerIsFixed && Style.mini)}
                action="/search"
                onSubmit={submitSearch}
                itemProp="potentialAction" 
                autoComplete='off'
                itemScope 
                itemType="https://schema.org/SearchAction"
            >
                <meta itemProp="target" content={`${process.env.NEXT_PUBLIC_siteUrl}/search?q={dfasdljk9werwu}`}/>

                <Input 
                    type="search"
                    name="dfasdljk9werwu"
                    id="dfasdljk9werwu"
                    className={Style.control}
                    label={placeholderText}
                    autoComplete={false}
                    onChange={search}
                    placeholder={"Search"}
                    value={query}
                    ref={searchControl}
                    itemProp="query-input"
                    canClear
                    onFocus={() => setInFocus(true)}
                    onBlur={() => setInFocus(false)}
                />
            </form>

            <div className={Style.typeahead}>
                <Typeahead {...{ isInFocus, query, isTrade }} />
            </div>
        </div>
    )
}


HeaderSearch.propTypes = {
    headerIsFixed: PropTypes.bool,
    isLandscape: PropTypes.bool,
    isMobile: PropTypes.bool,
    order: PropTypes.number
};


export default HeaderSearch;